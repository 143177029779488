import { Projection, fromLonLat } from 'ol/proj';
import {
  createArrowFeature,
  createLinestringFromPoints,
} from './Map/MapFunctions';

import { ApiVehicleLocation } from '@root/server/types';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import { Coordinate } from 'openlayers';

export const colorCodes = {
  auraus: '#19FFF1',
  hiekoitus: '#913333',
  puhtaanapito: '#fd0ae9',
};

/**
 * Create LineString -features from the vehicle data
 */
export function createVehicleLines(
  vehicleData: ApiVehicleLocation[],
  projection: Projection,
) {
  const linesObject = vehicleData
    .map((entry) => ({
      coordinates: fromLonLat([entry.lon, entry.lat], projection),
      color: entry.workStates[0]?.tag
        ? colorCodes[entry.workStates[0].tag as keyof typeof colorCodes]
        : '#4a7bf7',
    }))
    // split lines based on work state tags
    .reduce(
      (splittedLines, parsedEntry) => {
        const lastLineIndex = splittedLines.lines.length - 1;
        if (
          splittedLines.lastColor &&
          splittedLines.lastColor === parsedEntry.color
        ) {
          splittedLines.lines[lastLineIndex].line.push(
            parsedEntry.coordinates as Coordinate,
          );
        } else {
          splittedLines.lines.push({
            color: parsedEntry.color,
            line: [parsedEntry.coordinates] as Coordinate[],
          });
        }

        return { lines: splittedLines.lines, lastColor: parsedEntry.color };
      },
      {
        lines: [] as { color: string; line: Coordinate[] }[],
        lastColor: null as string | null,
      },
    );

  return linesObject.lines
    .map(parseLineObject) // line needs at least two coodinate points to be created
    .filter((linesObject) => linesObject.line.length >= 2)
    .map((lineObject) =>
      createLinestringFromPoints(lineObject.line, lineObject.color),
    );
}
/**
 * Create Point -feature with icon from the vehicle data
 */
function createVehicleArrows(
  vehicleData: ApiVehicleLocation[],
  projection: Projection,
) {
  return vehicleData
    .filter((_entry: any, index: number) => index % 20 === 0)
    .map((entry: any) =>
      createArrowFeature(
        { coordinates: [entry.lon, entry.lat], direction: entry.heading },
        projection,
        entry.workStates[0]?.tag
          ? colorCodes[entry.workStates[0].tag as keyof typeof colorCodes]
          : '#4a7bf7',
      ),
    );
}
/**
 * Create vehicle features from a data array
 */
export function createVehicleFeatures(
  data: ApiVehicleLocation[][],
  projection: Projection,
) {
  if (!data || data.length === 0) return [] as Feature<LineString>[];

  return data.flatMap((vehicleData) => {
    const vehicleLines = createVehicleLines(vehicleData, projection);
    const vehicleArrows = createVehicleArrows(vehicleData, projection);
    return [...vehicleLines, ...vehicleArrows];
  });
}

function parseLineObject(lineObject: { color: string; line: Coordinate[] }) {
  const nonDuplicateLine = lineObject.line.reduce((newLine, item) => {
    if (
      newLine
        .map((coord) => JSON.stringify(coord))
        .indexOf(JSON.stringify(item)) === -1
    ) {
      return [...newLine, item];
    }
    return newLine;
  }, [] as Coordinate[]);

  return { line: nonDuplicateLine, color: lineObject.color };
}
